import { Check } from 'react-bootstrap-icons';
import PropTypes from "prop-types";
import React from "react";

function ChoiceButton({chosen, clickHandler, correct, disabled, number, statement, value}) {
  const className = chosen == value ? "btn btn-primary" : "btn";
  const icon = (correct) ? <Check /> : null;
  const style = {textTransform: "none"};
  return (
    <button
      className={className}
      disabled={disabled}
      id={`choice-${number}`}
      onClick={clickHandler}
      style={style}
      type="button"
      value={value}
    >
      {`(${number}) ${statement}`} {icon}
    </button>
  );
}

ChoiceButton.propTypes = {
  chosen: PropTypes.any,
  clickHandler: PropTypes.func,
  correct: PropTypes.bool,
  disabled: PropTypes.string,
  number: PropTypes.number,
  statement: PropTypes.string.isRequired,
  value: PropTypes.any
};

export default ChoiceButton;