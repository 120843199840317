import React, { useState } from "react";
import PropTypes from "prop-types";

const Alert = ({message, status}) => {
  const [active, setActive] = useState(true);
  const className = `alert alert-${status}`;
  const close = () => {
    setActive(false);
  };

  if (active) {
    return (
      <div className={className} id="notice">
        <button type="button" className="btn-close" onClick={close}></button>
        {message}
      </div>
    );
  } else {
    return null;
  }
};

Alert.propTypes = {
  message: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired
};

export default Alert;
