import { Check, SlashCircle } from 'react-bootstrap-icons';
import React, { useState } from "react";
import PropTypes from "prop-types";
import ExerciseCard from "components/ExerciseCard";
import ExerciseInput from '../ExerciseInput';

function NounDeclension(props) {
  const [state, setState] = useState({});

  function alreadyAnswered() {
    return !!state.solution;
  }

  function audio() {
    if (alreadyAnswered()) {
      return state.solution.spoken_nouns;
    } else {
      return props.spoken_nouns;
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);

    fetch('/excercise_grades/create', {
      method: 'POST',
      body: data,
    }).then(response => response.json()).then((json) => {
      setState({ answer: json.answer, correct: json.correct, solution: json.solution });
    });
  }

  function heading() {
    let text = `${props.declension} Declension ${props.gender} Gender`;
    if (alreadyAnswered() && state.correct) {
      return <h3>{text} <Check /></h3>;
    } else {
      return <h3>{text}</h3>;
    }
  }

  function latinWord(word) {
    if (word.value) {
      return <span>{word.value}</span>;
    } else {
      return targetWord(word);
    }
  }

  function table() {
    const nouns = props.nouns;
    return (
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Case</th>
            <th scope="col">Singular</th>
            <th scope="col">Plural</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">Nominative</th>
            <td>{latinWord(nouns[0])}</td>
            <td>{latinWord(nouns[5])}</td>
          </tr>
          <tr>
            <th scope="row">Genitive</th>
            <td>{latinWord(nouns[1])}</td>
            <td>{latinWord(nouns[6])}</td>
          </tr>
          <tr>
            <th scope="row">Dative</th>
            <td>{latinWord(nouns[2])}</td>
            <td>{latinWord(nouns[7])}</td>
          </tr>
          <tr>
            <th scope="row">Accusative</th>
            <td>{latinWord(nouns[3])}</td>
            <td>{latinWord(nouns[8])}</td>
          </tr>
          <tr>
            <th scope="row">Ablative</th>
            <td>{latinWord(nouns[4])}</td>
            <td>{latinWord(nouns[9])}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  function targetWord(word) {
    if (alreadyAnswered()) {
      if (state.correct) {
        return <span>{state.solution.value} <Check /></span>;
      } else {
        return <span>{state.solution.value} <SlashCircle /></span>;
      }
    } else {
      return <ExerciseInput autoFocus name="answer[value]" size={word.length} type={word.type} />;
    }
  }

  return (
    <ExerciseCard {...props} audio={audio()} autoPlay={props.autoplay} answered={state.solution}>
      <form className="text-monospace" id="exercise-form" onSubmit={handleSubmit}>
        <input type="hidden" name="exercise_id" value={props.exercise_id} />
        {heading()}
        {table()}
      </form>
    </ExerciseCard>
  );
}

NounDeclension.propTypes = {
  autoplay: PropTypes.bool,
  declension: PropTypes.string,
  exercise_id: PropTypes.number.isRequired,
  gender: PropTypes.string,
  nouns: PropTypes.array.isRequired,
  spoken_nouns: PropTypes.string
};

export default NounDeclension;
