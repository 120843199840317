import { Check, SlashCircle } from 'react-bootstrap-icons';
import React, { useState } from "react";
import PropTypes from "prop-types";
import ExerciseCard from "components/ExerciseCard";
import ExerciseInput from "components/ExerciseInput";

function ClozeDeletion(props) {
  const [state, setState] = useState({});

  function alreadyAnswered() {
    return !!state.solution;
  }

  function handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);

    fetch('/excercise_grades/create', {
      method: 'POST',
      body: data,
    }).then(response => response.json()).then((json) => {
      setState({ answer: json.answer, correct: json.correct, solution: json.solution });
    });
  }

  function audio() {
    if (alreadyAnswered()) {
      return state.solution.spoken_statement;
    } else {
      return props.spoken_statement;
    }
  }

  function statement() {
    if (alreadyAnswered()) {
      if (state.correct) {
        return <span>{state.solution.statement} <Check /></span>;
      } else {
        return (
          <div>
            <div>{state.solution.statement}</div>
            <div><strike>{state.answer.values.join(', ')}</strike> <SlashCircle /></div>
          </div>
        )
      }
    } else {
      let inputIndex = 0;
      return props.statement.map((object, index) => {
        if (object.type) {
          let autoFocus = !inputIndex;
          inputIndex++;
          return <ExerciseInput autoFocus={autoFocus} key={index} name="answer[values][]" size={object.length}  type={object.type} />;
        } else {
          return <span key={index}>{object.value} </span>;
        }
      });
    }
  }

  return (
    <ExerciseCard {...props} audio={audio()} autoPlay={props.autoplay} answered={state.solution}>
      <form autoComplete="off" autoCorrect="off" className="text-monospace" id="exercise-form" onSubmit={handleSubmit}>
        <input type="hidden" name="exercise_id" value={props.exercise_id} />
        {statement()}
      </form>
    </ExerciseCard>
  );
}

ClozeDeletion.propTypes = {
  autoplay: PropTypes.bool,
  exercise_id: PropTypes.number.isRequired,
  spoken_statement: PropTypes.string,
  statement: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default ClozeDeletion;
