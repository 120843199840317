import React from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import PropTypes from "prop-types";

const NavBar = ({brand, current_path, dropdown_items, items, repetitions_remaining}) => {
  const badge = (klass, value) => {
    if (value) {
      let classes = `badge bg-secondary rounded-pill ${klass}`;
      return (
        <span className={classes}>{value}</span>
      );
    } else {
      return null;
    }
  };

  const dropdownLinks = () => {
    return dropdown_items.map((link) => {
      return (
        <NavDropdown.Item href={link.href} key={link.name}>
          {link.name}
        </NavDropdown.Item>
      )
    });
  };

  const dropdownTitle = () => {
    return dropdown_items.find((link) => {
      if (link.href === current_path) {
        return link.name;
      } else {
        return false;
      }
    }) || "Grammar";
  }

  const links = () => {
    return items.map((link) => {
      let active = link["href"] === current_path ? "active" : "";
      let navItemClass = `nav-item ${active}`;

      return (
        <Nav.Link
          className={navItemClass}
          data-method={link["data-method"]}
          href={link.href}
          key={link.name}
          rel={link.rel}
          target={link.target}
        >
          {link["name"]} {badge("d-none d-lg-inline", link["data-badge-value"])}
        </Nav.Link>
      );
    });
  };

  return (
    <Navbar bg="light" className="navbar navbar-expand-lg navbar-light bg-light fixed-top" expand="lg">
      <Container>
        <Navbar.Brand href={brand.href}>
          Boethius (βετα)
          {badge("d-lg-none", repetitions_remaining)}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" id="nav-bar-toggler" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {links()}
            <NavDropdown title={dropdownTitle()} id="basic-nav-dropdown">
              {dropdownLinks()}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

NavBar.defaultProps = {
  current_path: "/",
  dropdown_items: []
};

NavBar.propTypes = {
  brand: PropTypes.object.isRequired,
  current_path: PropTypes.string.isRequired,
  dropdown_items: PropTypes.arrayOf(PropTypes.object),
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  repetitions_remaining: PropTypes.number
};

export default NavBar;
