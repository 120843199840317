import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ExerciseCard from "components/ExerciseCard";
import ChoiceButton from "components/ChoiceButton";

const MultipleChoice = (props) => {
  const { autoplay, choices, exercise_id, question, spoken_question } = props;
  const [state, setState] = useState({ chosen: "" });

  const alreadyAnswered = () => {
    return !!state.solution;
  };

  const audio = () => {
    if (alreadyAnswered()) {
      return state.solution.spoken;
    } else {
      return spoken_question;
    }
  };

  const choicesElements = () =>
    choices.map((object, index) => {
      const aJudiciousChoiceSire =
        state.solution && state.solution.value === object.value;
      const number = index + 1;
      return (
        <ChoiceButton
          chosen={state.chosen}
          clickHandler={handleClick}
          correct={aJudiciousChoiceSire}
          disabled={disabled()}
          key={object.value}
          number={number}
          statement={object.statement}
          value={object.value}
        />
      );
    });

  useEffect(() => {
    const handleKeyPress = (event) => {
      const enterButton = 13;
      const firstChoice = 49;
      const lastChoice = choices.length + 48;
      const key = event.keyCode;

      if (key >= firstChoice && key <= lastChoice) {
        event.preventDefault();
        const number = key - 48;
        const button = document.getElementById(`choice-${number}`);
        button && button.click();
      } else if (key === enterButton) {
        const button = document.getElementById(`answer-button`);
        button && button.click();
      }
    };

    window.document.onkeydown = handleKeyPress;

    return () => {
      window.document.onkeydown = null;
    };
  }, [choices.length]);

  const disabled = () => {
    return alreadyAnswered() ? "disabled" : "";
  };

  const handleClick = (event) => {
    event.preventDefault();
    setState({ ...state, chosen: event.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);

    fetch("/excercise_grades/create", {
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((json) => {
        setState({ ...state, answer: json.answer, correct: json.correct, solution: json.solution });
      });
  };

  const questionElement = () => {
    return <div>{question}</div>;
  };

  return (
    <ExerciseCard {...props} audio={audio()} autoPlay={autoplay} answered={state.solution}>
      <form className="text-monospace" id="exercise-form" onSubmit={handleSubmit}>
        <input type="hidden" name="answer[value]" value={state.chosen} />
        <input type="hidden" name="exercise_id" value={exercise_id} />
        {questionElement()}
        <div className="btn-group-vertical">{choicesElements()}</div>
      </form>
    </ExerciseCard>
  );
};

MultipleChoice.propTypes = {
  autoplay: PropTypes.bool,
  choices: PropTypes.array.isRequired,
  exercise_id: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired,
  spoken_question: PropTypes.string,
};

export default MultipleChoice;
