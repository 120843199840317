import React, { useState, useRef, useEffect } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Book, CheckLg, Lock, Pencil } from 'react-bootstrap-icons';
import PropTypes from "prop-types";

const UserLessonButton = (props) => {
  const {admin, card_id, completed, edit_path, link_name, path, subtitle, position, title} = props;
  const [showPopover, setShowPopover] = useState(true);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        setShowPopover(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [buttonRef]);

  function autoFocus() {
    return firstUncompletedLesson() ;
  }

  function className() {
    let classes = ["btn", "btn-lg", "btn3d", "rounded-circle"];

    if (completed) {
      classes.push("btn-warning");
    } else if (firstUncompletedLesson()) {
      classes.push("btn-success");
    } else if (locked()) {
      classes.push("btn-dark");
    } else {
      classes.push("btn-info");
    }

    return classes.join(" ");
  }

  function editLink() {
    const href = edit_path;
    if (href && admin) {
      return <a className="card-link" href={href}>Edit</a>;
    } else {
      return null;
    }
  }

  function firstUncompletedLesson() {
    return position === 1;
  }

  function handleButtonClick() {
    setShowPopover(!showPopover);
  }

  function icon() {
    if (completed) {
      return <CheckLg size={iconSize()} />;
    } else if (locked()) {
      return <Lock size={iconSize()} />
    } else {
      if (link_name === "Learn") {
        return <Book size={iconSize()} />;
      } else {
        return <Pencil size={iconSize()} />;
      }
    }
  }

  function iconSize() {
    return 32;
  }

  function link() {
    const classes = ["btn", "btn-primary", "shadow-lg"];
    if (locked()) {
      classes.push("disabled");
    }

    return <a className={classes.join(" ")} href={path}>{link_name}</a>;
  }

  function locked() {
    if (position) {
      return position > 1;
    } else {
      return false;
    }
  }

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">{title}</Popover.Header>
      <Popover.Body>
        <div className={"d-grid"} id={card_id}>
          <p>{subtitle}</p>
          {link()} {editLink()}
        </div>
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger trigger="click" placement="right" overlay={popover}>
      <button
        autoFocus={autoFocus()}
        id={`lesson-button-${card_id}`}
        className={className()}
        ref={buttonRef}
        onClick={handleButtonClick}
        tabIndex={position}
      >
        {icon()}
      </button>
    </OverlayTrigger>
  );
};

UserLessonButton.propTypes = {
  admin: PropTypes.bool.isRequired,
  card_id: PropTypes.string.isRequired,
  completed: PropTypes.bool.isRequired,
  edit_path: PropTypes.string,
  link_name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  position: PropTypes.number,
  title: PropTypes.string.isRequired
};

export default UserLessonButton;
