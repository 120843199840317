import { Fire } from 'react-bootstrap-icons';
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

function ExerciseCard(props) {
  const [exerciseAudioPlayed, setExerciseAudioPlayed] = useState(false);
  const [solutionAudioPlayed, setSolutionAudioPlayed] = useState(false);

  const speak = () => {
    if (window.responsiveVoice) {
      window.responsiveVoice.speak(props.audio, props.voice, props.voice_options);
      if (props.answered) {
        setSolutionAudioPlayed(true);
      } else {
        setExerciseAudioPlayed(true);
      }
    } else {
      console.log("ResponsiveVoice doesn't seem to be responding, so audio playback is disabled temporarily")
    }
  };

  const stopSpeaking = () => {
    window.responsiveVoice && window.responsiveVoice.cancel();
  };

  const primaryLink = () => {
    if (props.answered) {
      return nextLink();
    } else {
      return answerButton();
    }
  };

  const answerButton = () => {
    const className = "btn btn-info";
    const form = "exercise-form";
    const role = "button";
    return <button className={className} id="answer-button" form={form} name="answerButton" onClick={stopSpeaking} role={role}>Answer</button>;
  };

  const badge = () => {
    if (reviewed()) {
      return null;
    } else {
      return <span className="badge rounded-pill bg-dark">New</span>;
    }
  };

  const cardClasses = () => {
    let classes = ["card"];

    if (!reviewed()) { classes.push("border-warning") }

    return classes.join(" ");
  };

  const cardFooter = () => {
    if (todays_workout_completed()) {
      return <div className="card-footer text-muted"><Fire />You already completed your daily workout!<Fire /></div>;
    } else {
      return null;
    }
  };

  const feedbackLink = () => {
    const {className, href, role} = props.feedback_link;
    return <a className={className} href={href} role={role} target="_blank" rel="noreferrer">Give Feedback</a>;
  };

  const hasAudio = () => {
    return props.audio;
  };

  const nextLink = () => {
    const {className, href, role} = props.next_link;
    return <a className={className} href={href} id="next-link" onClick={stopSpeaking} role={role}>Next</a>;
  };

  const reviewed = () => {
    return props.reviewed;
  };

  const speakButton = () => {
    if (hasAudio()) {
      return <a className="btn btn-secondary card-link" onClick={speak} role="button">Replay Audio</a>;
    } else {
      return null;
    }
  };

  const todays_workout_completed = () => {
    return props.todays_workout_completed;
  };

  useEffect(() => {
    if (props.autoPlay && props.audio && (!exerciseAudioPlayed || !solutionAudioPlayed)) {
      speak();
    }
  }, [props.answered, props.autoPlay, props.audio, props.voice, props.voice_options]);

  useEffect(() => {
    let nextButton = window.document.getElementById("next-link");
    nextButton && nextButton.focus();
  }, [props.answered]);

  return (
    <div className={cardClasses()} id="exercise-card">
      <h5 className="card-header">{badge()} {props.title} - {props.subtitle}</h5>
      <div className="card-body">
        <span className="card-text text-monospace">
          {props.children}
        </span>
        <div className="btn-group d-flex" role="group" aria-label="Exercise card controls">
          {primaryLink()}
          {speakButton()}
          {feedbackLink()}
        </div>
        {cardFooter()}
      </div>
    </div>
  );
}

ExerciseCard.defaultProps = {
  answered: false,
  autoPlay: false,
  latin: "classical",
  reviewed: false,
  todays_workout_completed: false,
  voice: "UK English Female",
  voice_options: {}
};

ExerciseCard.propTypes = {
  answered: PropTypes.bool.isRequired,
  audio: PropTypes.string,
  autoPlay: PropTypes.bool.isRequired,
  children: PropTypes.object,
  feedback_link: PropTypes.shape({
    className: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired
  }),
  next_link: PropTypes.shape({
    className: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired
  }),
  reviewed: PropTypes.bool.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  todays_workout_completed: PropTypes.bool.isRequired,
  voice: PropTypes.string.isRequired,
  voice_options: PropTypes.shape({
    rate: PropTypes.number,
    volume: PropTypes.number
  }).isRequired
};

export default ExerciseCard;
