import { Check, SlashCircle } from 'react-bootstrap-icons';
import React, { useState } from "react";
import PropTypes from "prop-types";
import ExerciseCard from "components/ExerciseCard";
import ExerciseInput from '../ExerciseInput';

function ImageRecognition(props) {
  const [state, setState] = useState({});

  function alreadyAnswered() {
    return !!state.solution;
  }

  function handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);

    fetch('/excercise_grades/create', {
      method: 'POST',
      body: data,
    }).then(response => response.json()).then((json) => {
      setState({ answer: json.answer, correct: json.correct, solution: json.solution });
    });
  }

  function input() {
    if (alreadyAnswered()) {
      if (state.correct) {
        return <span>{state.solution.value} <Check /></span>;
      } else {
        return <span>{state.solution.value} <SlashCircle /></span>;
      }
    } else {
      return <ExerciseInput autoFocus name="answer[value]" />;
    }
  }

  function audio() {
    if (alreadyAnswered()) {
      return state.solution.value;
    } else {
      return props.statement;
    }
  }

  function statement() {
    return <div>{props.statement}</div>;
  }

  return (
    <ExerciseCard {...props} audio={audio()} autoPlay={props.autoplay} answered={state.solution}>
      <form className="text-monospace" id="exercise-form" onSubmit={handleSubmit}>
        <input type="hidden" name="exercise_id" value={props.exercise_id} />
        <img
          className="img-fluid rounded"
          src={props.picture.src}
          alt="..."
          style={{width: props.picture.width || '300px', height: props.picture.height || '300px'}}
          >
          </img>
        {statement()}
        {input()}
      </form>
    </ExerciseCard>
  );
}

ImageRecognition.propTypes = {
  autoplay: PropTypes.bool,
  exercise_id: PropTypes.number.isRequired,
  picture: PropTypes.object.isRequired,
  statement: PropTypes.string.isRequired,
};

export default ImageRecognition
