import PropTypes from "prop-types";
import React from "react";

function ExerciseInput(props) {
  return (
    <input
      autoCapitalize="off"
      autoComplete="off"
      autoCorrect="off"
      className="exercise-input"
      spellCheck={false}
      {...props}
    />
  );
}

ExerciseInput.propTypes = { type: PropTypes.string };

export default ExerciseInput;
