import { Check, SlashCircle } from 'react-bootstrap-icons';
import React, { useState } from "react";
import PropTypes from "prop-types";
import ExerciseCard from "components/ExerciseCard";

function FractionAddition(props) {
  const [state, setState] = useState({});

  function alreadyAnswered() {
    return !!state.solution;
  }

  function answer() {
    if (alreadyAnswered()) {
      if (state.correct) {
        return <span><sup>{state.solution.numerator}</sup>&frasl;<sub>{state.solution.denominator}</sub><Check /></span>;
      } else {
        return <span><strike><sup>{state.answer.numerator}</sup>&frasl;<sub>{state.answer.denominator}</sub></strike> <SlashCircle /> <sup>{this.state.solution.numerator}</sup>&frasl;<sub>{state.solution.denominator}</sub></span>;
      }
    } else {
      return <span><input autoFocus name="answer[numerator]" type="number"></input> / <input name="answer[denominator]" type="number"></input></span>;
    }
  }

  function audio() {
    if (alreadyAnswered()) {
      return state.solution.spoken_equation;
    } else {
      return props.spoken_equation;
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);

    fetch('/excercise_grades/create', {
      method: 'POST',
      body: data,
    }).then(response => response.json()).then((json) => {
      setState({ answer: json.answer, correct: json.correct, solution: json.solution });
    });
  }

  return (
    <ExerciseCard {...props} audio={audio()} autoPlay={props.autoplay} answered={state.solution}>
      <form className="text-monospace" id="exercise-form" onSubmit={handleSubmit}>
        <input type="hidden" name="exercise_id" value={props.exercise_id} />
        <span><sup>{props.first_numerator}</sup>&frasl;<sub>{props.first_denominator}</sub> + <sup>{props.second_numerator}</sup>&frasl;<sub>{props.second_denominator}</sub> = {answer()}</span>
      </form>
    </ExerciseCard>
  );
}

FractionAddition.propTypes = {
  autoplay: PropTypes.bool,
  exercise_id: PropTypes.number.isRequired,
  first_numerator: PropTypes.number.isRequired,
  first_denominator: PropTypes.number.isRequired,
  second_numerator: PropTypes.number.isRequired,
  second_denominator: PropTypes.number.isRequired,
  spoken_equation: PropTypes.string
};

export default FractionAddition;
