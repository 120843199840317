import React, { useState } from "react";
import PropTypes from "prop-types";
import ExerciseCard from "components/ExerciseCard";

function WordBankTranslation(props) {
  const [availableWords, setAvailableWords] = useState(props.words);
  const [selectedWords, setSelectedWords] = useState([]);
  const [submissionState, setSubmissionState] = useState({ correct: null, solution: null });

  function alreadyAnswered() {
    return !!submissionState.solution;
  }

  function handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);

    fetch('/excercise_grades/create', {
      method: 'POST',
      body: data,
    }).then(response => response.json()).then((json) => {
      setSubmissionState({
        correct: json.correct,
        solution: json.solution,
      });
    });
  }

  function addWord(word) {
    setSelectedWords([...selectedWords, word]);
    setAvailableWords(availableWords.filter(w => w !== word));
  }

  function removeWord(word) {
    setSelectedWords(selectedWords.filter(w => w !== word));
    setAvailableWords([...availableWords, word]);
  }

  function wordButtons() {
    return availableWords.map((word, index) => (
      <button key={index} type="button" className="btn btn-secondary m-1" onClick={() => addWord(word)}>
        {word.value}
      </button>
    ));
  }

  function answerArea() {
    if (alreadyAnswered()) {
      return (
        <div>
          <p>Your answer: {selectedWords.map(word => word.value).join(' ')}</p>
          <p>Correct answer: {submissionState.solution.translation.map(word => word.value).join(' ')}</p>
          <p>{submissionState.correct ? 'Correct!' : 'Incorrect'}</p>
        </div>
      );
    } else {
      return (
        <div>
          <p>
            {selectedWords.length > 0
              ? selectedWords.map((word, index) => (
                  <span key={index} className="badge badge-pill badge-primary m-1" style={{ cursor: 'pointer', color: 'black' }} onClick={() => removeWord(word)}>
                    {word.value} <span className="ml-1">&times;</span>
                  </span>
                ))
              : "_________"}
          </p>
        </div>
      );
    }
  }

  function sentenceArea() {
    return props.sentence.map((word, index) => {
      let classes = [];

      if (word.target) {
        classes.push("text-warning");
      }

      return <span key={index} className={classes.join(" ")}>{word.value} </span>;
    });
  }

  return (
    <ExerciseCard {...props} answered={!!submissionState.solution}>
      <form autoComplete="off" autoCorrect="off" className="text-monospace" id="exercise-form" onSubmit={handleSubmit}>
        <input type="hidden" name="exercise_id" value={props.exercise_id} />
        <input type="hidden" name="answer[translation]" value={JSON.stringify(selectedWords)} />

        <h3>Translate the highlighted part of the sentence:</h3>
        <p>{sentenceArea()}</p>
        <div>{wordButtons()}</div>
        {answerArea()}

      </form>
    </ExerciseCard>
  );
}

WordBankTranslation.propTypes = {
  autoplay: PropTypes.bool,
  exercise_id: PropTypes.number.isRequired,
  sentence: PropTypes.arrayOf(PropTypes.object).isRequired,
  solution: PropTypes.object.isRequired,
  words: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default WordBankTranslation;
