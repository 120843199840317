import { Check, SlashCircle } from 'react-bootstrap-icons';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ExerciseCard from 'components/ExerciseCard';

function DecimalAddition(props) {
  const [state, setState] = useState({});

  function alreadyAnswered() {
    return !!state.solution;
  }

  function answer() {
    if (alreadyAnswered()) {
      if (state.correct) {
        return (
          <span>
            {state.solution.value} <Check />
          </span>
        );
      } else {
        return (
          <span>
            <strike>{state.answer.value}</strike> <SlashCircle /> {state.solution.value}
          </span>
        );
      }
    } else {
      return <input autoFocus name="answer[value]" step={0.1} type="number"></input>;
    }
  }

  function audio() {
    if (alreadyAnswered()) {
      return state.solution.spoken_equation;
    } else {
      return props.spoken_equation;
    }
  }

  function handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);

    fetch('/excercise_grades/create', {
      method: 'POST',
      body: data,
    })
      .then((response) => response.json())
      .then((json) => {
        setState({ answer: json.answer, correct: json.correct, solution: json.solution });
      });
  }

  return (
    <ExerciseCard {...props} audio={audio()} autoPlay={props.autoplay} answered={state.solution}>
      <form className="text-monospace" id="exercise-form" onSubmit={handleSubmit}>
        <input type="hidden" name="exercise_id" value={props.exercise_id} />
        <span>
          {props.first_argument} + {props.second_argument} = {answer()}
        </span>
      </form>
    </ExerciseCard>
  );
}

DecimalAddition.propTypes = {
  autoplay: PropTypes.bool,
  exercise_id: PropTypes.number.isRequired,
  first_argument: PropTypes.number.isRequired,
  second_argument: PropTypes.number.isRequired,
  spoken_equation: PropTypes.string,
};

export default DecimalAddition;
