import { Check, SlashCircle } from 'react-bootstrap-icons';
import React, { useState } from "react";
import PropTypes from "prop-types";
import ExerciseCard from "components/ExerciseCard";
import ExerciseInput from '../ExerciseInput';

function VerbConjugation(props) {
  const [state, setState] = useState({});

  function alreadyAnswered() {
    return !!state.solution;
  }

  function audio() {
    if (alreadyAnswered()) {
      return state.solution.spoken_verbs;
    } else {
      return props.spoken_verbs;
    }
  }

  function englishWord(word) {
    return <span className="small" key={word.value}>({word.value})</span>;
  }

  function handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);

    fetch('/excercise_grades/create', {
      method: 'POST',
      body: data,
    }).then(response => response.json()).then((json) => {
      setState({ answer: json.answer, correct: json.correct, solution: json.solution });
    });
  }

  function heading() {
    let text = `${props.conjugation} Conjugation - ${props.tense} Tense`;
    return <h3>{text}</h3>;
  }

  function latinWord(word) {
    if (word.value) {
      return <span>{word.value}</span>;
    } else {
      return targetWord(word);
    }
  }

  function table() {
    const [ first, second, third, fourth, fifth, sixth ] = props.verbs;
    return (
      <table className="table table-hover">
        <thead>
          <tr>
            <th scope="col">Person</th>
            <th scope="col">Singular</th>
            <th scope="col">Plural</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">First</th>
            <td>{latinWord(first[0])} {englishWord(first[1])}</td>
            <td>{latinWord(fourth[0])} {englishWord(fourth[1])}</td>
          </tr>
          <tr>
            <th scope="row">Second</th>
            <td>{latinWord(second[0])} {englishWord(second[1])}</td>
            <td>{latinWord(fifth[0])} {englishWord(fifth[1])}</td>
          </tr>
          <tr>
            <th scope="row">Third</th>
            <td>{latinWord(third[0])} {englishWord(third[1])}</td>
            <td>{latinWord(sixth[0])} {englishWord(sixth[1])}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  function subheading() {
    const infinitive = props.infinitive;

    if (infinitive) {
      return <h4>{infinitive}</h4>;
    } else {
      return null;
    }
  }

  function targetWord(word) {
    if (alreadyAnswered()) {
      if (state.correct) {
        return <span>{state.solution.value} <Check /></span>;
      } else {
        return <span>{state.solution.value} <SlashCircle /></span>;
      }
    } else {
      return <ExerciseInput autoFocus name="answer[value]" size={word.length} type={word.type} />;
    }
  }

  return (
    <ExerciseCard {...props} audio={audio()} autoPlay={props.autoplay} answered={state.solution}>
      <form autoComplete="off" autoCorrect="off" className="text-monospace" id="exercise-form" onSubmit={handleSubmit}>
        <input type="hidden" name="exercise_id" value={props.exercise_id} />
        {heading()}
        {subheading()}
        {table()}
      </form>
    </ExerciseCard>
  );
}

VerbConjugation.propTypes = {
  autoplay: PropTypes.bool,
  conjugation: PropTypes.string,
  exercise_id: PropTypes.number.isRequired,
  infinitive: PropTypes.string,
  tense: PropTypes.string,
  spoken_verbs: PropTypes.string,
  verbs: PropTypes.array.isRequired,
};

export default VerbConjugation;
