import { Check, SlashCircle } from 'react-bootstrap-icons';
import React, { useState } from "react";
import PropTypes from "prop-types";
import ExerciseCard from "components/ExerciseCard";
import ExerciseInput from '../ExerciseInput';

function Translation(props) {
  const [state, setState] = useState({});

  function alreadyAnswered() {
    return !!state.solution;
  }

  function audio() {
    if (alreadyAnswered()) {
      return state.solution.spoken_lines;
    } else {
      return props.spoken_lines;
    }
  }

  function buildLine(line) {
    return line.map((word, index) => {
      if (!word.value) {
        return (
          <span key={index}>
            <ruby>
              <ExerciseInput autoFocus={true} key={index} name="answer[values][]" size={word.length} type={word.type} /> <rt>{word.meaning}</rt>
            </ruby>
            {" "}
          </span>
        )
      } else {
        return (
          <span key={index}>
            <ruby>
              {word.value} <rt>{word.meaning}</rt>
            </ruby>
            {" "}
          </span>
        )
      }
    });
  }

  function handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);

    fetch('/excercise_grades/create', {
      method: 'POST',
      body: data,
    }).then(response => response.json()).then((json) => {
      setState({ answer: json.answer, correct: json.correct, solution: json.solution });
    });
  }

  function lines() {
    if (alreadyAnswered()) {
      return state.solution.lines.map((line, index) => {
        return <p key={index}>{buildLine(line)}</p>;
      })
    } else {
      return props.lines.map((line, index) => {
        return <p key={index}>{buildLine(line)}</p>;
      });
    }
  }

  function outcomeIcon() {
    if (state.solution) {
      return (state.correct ? <Check></Check> : <SlashCircle></SlashCircle> );
    } else {
      return null;
    }
  }

  return (
    <ExerciseCard {...props} audio={audio()} autoPlay={props.autoplay} answered={state.solution}>
      <form autoComplete="off" autoCorrect="off" className="text-center text-monospace" id="exercise-form" onSubmit={handleSubmit}>
        <input type="hidden" name="exercise_id" value={props.exercise_id} />
        {lines()} {outcomeIcon()}
      </form>
    </ExerciseCard>
  );
}

Translation.propTypes = {
  autoplay: PropTypes.bool,
  exercise_id: PropTypes.number.isRequired,
  lines: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
  spoken_lines: PropTypes.string
};

export default Translation;
