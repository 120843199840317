import ChoiceButton from "components/ChoiceButton";
import { Check, SlashCircle } from 'react-bootstrap-icons';
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ExerciseCard from "components/ExerciseCard";
import capitalize from "../../shared/capitalize";

function MultipleChoiceTranslation(props) {
  const [state, setState] = useState({ chosen: "" });

  useEffect(() => {
    const handleKeyPress = (event) => {
      const enterButton = 13;
      const firstChoice = 49;
      const lastChoice = props.choices.length + 48;
      const key = event.keyCode;

      if (key >= firstChoice && key <= lastChoice) {
        event.preventDefault();
        const number = key - 48;
        const button = window.document.getElementById(`choice-${number}`);
        button && button.click();
      } else if (key === enterButton) {
        const button = window.document.getElementById(`answer-button`);
        button && button.click();
      }
    };

    window.document.onkeydown = handleKeyPress;

    return () => {
      window.document.onkeydown = null;
    };
  }, [props.choices.length]);

  function alreadyAnswered() {
    return !!state.solution;
  }

  function audio() {
    if (alreadyAnswered()) {
      return state.solution.spoken_lines;
    } else {
      return props.spoken_lines;
    }
  }

  function buildLine(line) {
    return line.map((word, index) => {
      if (!word.value) {
        if (alreadyAnswered()) {
          const firstWord = index === 0;
          const statement = state.solution.statement;

          return (
            <span key={index}>
              <ruby>
                {firstWord ? capitalize(statement) : statement} {outcomeIcon()}<rt>{word.meaning}</rt>
              </ruby>
              {" "}
            </span>
          );
        } else {
          let foo = "*****";
          return (
            <span key={index}>
              <ruby>
                {foo} <rt>{word.meaning}</rt>
              </ruby>
              {" "}
            </span>
          )
        }
      } else {
        return (
          <span key={index}>
            <ruby>
              {word.value} <rt>{word.meaning}</rt>
            </ruby>
            {" "}
          </span>
        )
      }
    });
  }

  function choices() {
    return props.choices.map((object, index) => {
      let aJudiciousChoiceSire = state.solution && state.solution.value == object.value;
      let number = index + 1;
      return (
        <ChoiceButton
          chosen={state.chosen}
          clickHandler={handleClick}
          correct={aJudiciousChoiceSire}
          disabled={disabled()}
          key={object.value}
          number={number}
          statement={object.statement}
          value={object.value}
        />
      );
    });
  }

  function disabled() {
    return alreadyAnswered() ? 'disabled' : '';
  }

  function handleClick(event) {
    event.preventDefault();
    setState({chosen: event.target.value});
  }

  function handleSubmit(event) {
    event.preventDefault();
    const data = new FormData(event.target);

    fetch('/excercise_grades/create', {
      method: 'POST',
      body: data,
    }).then(response => response.json()).then((json) => {
      setState({ answer: json.answer, correct: json.correct, solution: json.solution });
    });
  }

  function lines() {
    return props.lines.map((line, index) => {
      return <p key={index}>{buildLine(line)}</p>;
    });
  }

  function hint() {
    if (props.hint) {
      return (
        <figcaption className="blockquote-footer">
          {props.hint}
        </figcaption>
      )
    } else {
      return null;
    }
  }

  function outcomeIcon() {
    if (state.solution) {
      return (state.correct ? <Check></Check> : <SlashCircle></SlashCircle> );
    } else {
      return null;
    }
  }

  function voice() {
    if (props.latin == "classical") {
      return "Latin Male";
    } else {
      return props.voice;
    }
  }

  return (
    <ExerciseCard {...props} audio={audio()} autoPlay={props.autoplay} answered={state.solution} voice={voice()}>
      <form autoComplete="off" autoCorrect="off" className="text-center text-monospace" id="exercise-form" onSubmit={handleSubmit}>
        <input type="hidden" name="answer[value]" value={state.chosen} />
        <input type="hidden" name="exercise_id" value={props.exercise_id} />
        <figure>
          <blockquote className="blockquote">
            {lines()}
          </blockquote>
          {hint()}
        </figure>
        <div className="btn-group-vertical">{choices()}</div>
      </form>
    </ExerciseCard>
  );
}

MultipleChoiceTranslation.propTypes = {
  autoplay: PropTypes.bool,
  exercise_id: PropTypes.number.isRequired,
  choices: PropTypes.arrayOf(PropTypes.object).isRequired,
  hint: PropTypes.string,
  latin: PropTypes.string,
  lines: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)).isRequired,
  spoken_lines: PropTypes.string,
  voice: PropTypes.string
};

export default MultipleChoiceTranslation;
