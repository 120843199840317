import { Check, Lock } from 'react-bootstrap-icons';
import React from "react";
import PropTypes from "prop-types";

const GradeTab = ({completed, current, href, locked, name}) => {
  const active = () => {
    if (current) {
      return "active";
    } else {
      return "";
    }
  };

  const disabled = () => {
    if (locked) {
      return "disabled";
    } else {
      return "";
    }
  };

  const icon = () => {
    if (completed) {
      return <Check />;
    } else if (locked) {
      return <Lock />;
    } else { 
      return null;
    }
  };

  const className = `nav-link ${active()} ${disabled()}`;
  return (
    <li className="nav-item">
      <a className={className} href={href}>{name} {icon()}</a>
    </li>
  );
}

GradeTab.propTypes = {
  completed: PropTypes.bool.isRequired,
  current: PropTypes.bool.isRequired,
  href: PropTypes.string.isRequired,
  locked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired
};

export default GradeTab;
